import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { CostingModel, CostingPrintModel } from '../../Models/CostingModel';
import { FormulationCodeModel } from 'src/PmsUIApp/Models/FormulationCodeModel';
import { ColorModel, GrainModel, PostProcessCostingMasterModel, ProductCategoryModel, ThicknessModel } from 'src/PmsUIApp/Models/MasterModel';
import { CustomerModel } from 'src/PmsUIApp/Models/SupplierModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import moment from 'moment';
import { SaleOrderCostingModel, SaleOrderCostingParamsModel, SaleOrderModel } from 'src/PmsUIApp/Models/SalesOrderModel';
import { SaleOrderCostingService } from 'src/PmsUIApp/Services/SaleOrderCostingService';
import { cos } from '@amcharts/amcharts5/.internal/core/util/Math';
import { SaleOrderCostingDataService } from 'src/PmsUIApp/Services/SaleOrderCostingData.service';
import { DatetimeConverter } from 'src/PmsUIApp/Services/DatetimeConverter.pipe';
@Component({
  selector: 'app-Costinglist',
  templateUrl: './Costinglist.component.html',
  styleUrls: ['./Costinglist.component.css'],
})
export class CostingListComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  CostingList: CostingModel[] = [];
  CostingListOriginal: CostingModel[] = [];
  PerLmData: CostingModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New Production';
  searchValue = '';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any;
  visible = false;
  exportoptions = {
    headers: [
      'S.No.',
      'Sale Order No.',
      'Customer Name',
      'Sale Order Code',
      'Product Type',
      'Alias',
      'Order QTY',
      'MFD QTY',
      'Rejection %',
      'Rejection Cost /LM',
      'Production Cost /LM',
      'Per LM',
      'Paste /LM',
      'Grain Cost /LM',
      'Fabric Cost /LM',
      'Print Cost /LM',
      'Embossing Cost /LM',
      'Vacuum Cost /LM',
      'Lacquer Cost /LM',
      'Inline Scraping Cost /LM',
      'Tumbling Cost /LM',
      'Finishing Cost /LM',
      'Raw Material Cost /LM',
      'Overhead Cost /LM',
      'Packaging Cost /LM',
      'Miscellaneous Cost /LM',
      'Total Cost /LM',
      'Total Fabric Cost',
      'Final Total Cost',
      'Profit/Loss Per LM',
      'Total Profit/Loss',
      'Costing Status',
      'Submitted By',
      'Submitted Date',
      'Production Completed Date'
    ],
  };
  fields: any;
  exportfields: CostingPrintModel[] = [];
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  totalItemsCount: number;
  isPerMtrDetailsVisible = false;
  request = {
    FromAddedDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 2)),
    ToAddedDate: new Date(new Date().setHours(23, 59, 59)),
    FromSaleOrderDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 2)),
    ToSaleOrderDate: new Date(new Date().setHours(23, 59, 59)),
    FromDeliveryDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    ToDeliveryDate: new Date(new Date().setHours(23, 59, 59)),
    FromProductionDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    ToProductionDate: new Date(new Date().setHours(23, 59, 59)),
    FromFinalInspectionDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    ToFinalInspectionDate: new Date(new Date().setHours(23, 59, 59)),
    SaleFormulationCodeId: 0,
    SaleOrderNumber: null,
    DateType: 'submitteddate', // options are - submitteddate, saleorderdate, deliverydate, productiondate, finalinspectiondate
    AddedBy: '',
    ArticleName: '',
    CustomerId: 0,
    GrainId: 0,
    ColorId: 0,
    ProductType: '', //options are - GZ- or GZY-
    OrderType: '',
    CostingStatus: '',
    ThicknessId: 0,
    CategoryId: 0,
    WorkShift: '',
    ProductionLineNo: 0,
  }
  dateFilterOptions: any = [
    {
      "Text": "Day Shift (8AM to 8PM)",
      "Value": 'dayshift'
    },
    {
      "Text": "Night Shift (8PM to 8AM)",
      "Value": 'nightshift'
    },
    {
      "Text": "Today",
      "Value": 'today'
    },
    {
      "Text": "Yesterday",
      "Value": 'yesterday'
    },
    {
      "Text": "Last 7 Days",
      "Value": 'last7days'
    },
    {
      "Text": "Last 30 Days",
      "Value": 'last30days'
    },
    {
      "Text": "Last Month",
      "Value": 'lastmonth'
    },
    {
      "Text": "Last Year",
      "Value": 'lastyear'
    },
    {
      "Text": "Custom Range",
      "Value": 'custom'
    }
  ];
  selecteddateFilter: string = 'today';
  dateTypeList = [
    {
      "Text": "Submitted",
      "Value": 'submitteddate'
    },
    {
      "Text": "Sale Order",
      "Value": 'saleorderdate'
    },
    {
      "Text": "Delivery",
      "Value": 'deliverydate'
    },
    {
      "Text": "Production Complete",
      "Value": 'productiondate'
    },
    {
      "Text": "Final Inspection Complete",
      "Value": 'finalinspectiondate'
    }];
  orderTypeList: any = [
    'Sample',
    'Trial',
    'Product',
    'Job Work'
  ];
  PUPVCTypeList: any = [
    {
      "Text": "GZ(PVC)",
      "Value": 'GZ-'
    },
    {
      "Text": "GZY(PU)",
      "Value": 'GZY-'
    }
  ]
  CostingStatus: any = [
    'Pending',
    'Partial Ready',
    'Ready',
    'Submitted'
  ];
  ProductionLineList: any = [
    {
      "Text": "All",
      "Value": '0'
    },
    {
      "Text": "Line 1",
      "Value": '1'
    },
    {
      "Text": "Line 2",
      "Value": '2'
    }
  ];
  WorkShiftList: any = [
    {
      "Text": "All",
      "Value": ''
    },
    {
      "Text": "Day Shift (8AM to 8PM)",
      "Value": 'day'
    },
    {
      "Text": "Night Shift (8PM to 8AM)",
      "Value": 'night'
    }
  ];
  CustomerList: CustomerModel[];
  FormulationCodeList: FormulationCodeModel[];
  ColorList: ColorModel[];
  GrainList: GrainModel[];
  isVisibleCosting: boolean;
  PostProcessCostingList: PostProcessCostingMasterModel[];
  InlineScraping: number;
  havePostProcess: boolean;
  Orderlist!: SaleOrderModel;
  FabricName: string;
  FabricColorName: string;
  RejectionConstant: number = 6;
  OverheadCost: number = 25;
  PerLMConstant: number = 1.45;
  ProductionCostLm: number = 0;
  TotalCostPerLm: number = 0;
  TotalCoating: number = 0;
  TotalFinalGSM: number = 0;
  TotalCostLm: number = 0;
  TotalLaquerPrice: number = 0;
  Rejection: number = 0;
  FabricProductCostPerLm: number = 0;
  MaterialType: string = 'PVC';
  ThicknessList: ThicknessModel[];
  ProductCategoryList: ProductCategoryModel[];
  enableCustomDateRange: boolean;
  enableWorkShiftDropDown: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    private alertService: AlertMessageService,
    private loader: LoadingService,
    private modalService: NzModalService, private auth: AuthService, private router: Router,
    private CostingView: SaleOrderCostingService,
    public costingService: SaleOrderCostingDataService
  ) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Costing, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Costing, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Costing, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.getDateRange("today");
    this.selecteddateFilter = 'today';

    this.GetFilteredCosting();
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'costing-list-export' + exportdate,
        this.exportoptions
      );
  }

  PrintListPage(): void {
    const filters: any = {
      FromAddedDate: this.request.FromAddedDate,
      ToAddedDate: this.request.ToAddedDate,
      FromSaleOrderDate: this.request.FromSaleOrderDate,
      ToSaleOrderDate: this.request.ToSaleOrderDate,
      FromDeliveryDate: this.request.FromDeliveryDate,
      ToDeliveryDate: this.request.ToDeliveryDate,
      SaleFormulationCodeId: this.request.SaleFormulationCodeId,
      SaleOrderNumber: this.request.SaleOrderNumber,
      DateType: this.request.DateType,
      AddedBy: this.request.AddedBy,
      ArticleName: this.request.ArticleName,
      CustomerId: this.request.CustomerId,
      GrainId: this.request.GrainId,
      ColorId: this.request.ColorId,
      ProductType: this.request.ProductType,
      OrderType: this.request.OrderType,
      CostingStatus: this.request.CostingStatus,
      CategoryId: this.request.CategoryId
    };
    const queryParams: any = {};
    for (const filter in filters) {
      if (filters[filter] !== undefined && filters[filter] !== null) {
        // Convert the date to a string with the desired format
        if (filters[filter] instanceof Date) {
          queryParams[filter] = filters[filter].toISOString();
        } else {
          queryParams[filter] = filters[filter];
        }
      }
    }
    const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
    const printUrl = `/costinglistprint?${queryString}`;
    window.open(printUrl, '_blank');
  }

  GetFilteredCosting() {
    this.isTableLoading = true;
    this.CostingListOriginal = null;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + 'costing/getfilteredcostinglist';
    this.http.post<CostingModel[]>(url, this.request).subscribe({
      next: res => {
        this.CostingListOriginal = res;
        count = this.totalItemsCount;
        this.exportfields = [];
        this.CostingListOriginal.forEach(x => {
          count++;
          x.SerialNo = count;
          this.fields = [];
          this.fields.SerialNo = x.SerialNo;
          this.fields.SaleOrderNumber = x.SaleOrderNumber;
          this.fields.CustomerName = x.CustomerName;
          this.fields.SaleOrderCode = x.SaleOrderCode;
          this.fields.SaleOrderMaterialType = x.SaleOrderMaterialType ?? '';
          this.fields.Article = x.SaleOrderProduction.ManufacturingProductName ?? '';
          this.fields.OrderQTY = x.SaleOrderProduction.OrderQuantity;
          this.fields.MFGQTY = x.SaleOrderProduction.ManufacturingQuantity ?? 0;
          this.fields.Rejection = x.Rejection ?? 0;
          this.fields.RejectionCostLm = x.RejectionCostLm ?? 0;
          this.fields.ProductionCostLm = x.ProductionCostLm ?? 0;
          this.fields.PerLmconstant = x.PerLmconstant ?? 0;
          this.fields.PasteCostLm = x.PasteCostLm ?? 0;
          this.fields.GrainCostLm = x.GrainCostLm ?? 0;
          this.fields.FabricCostLm = x.FabricCostLm ?? 0;
          this.fields.PrintCostPerUnit = x.PrintCostPerUnit ?? 0;
          this.fields.EmbossingCostPerUnit = x.EmbossingCostPerUnit ?? 0;
          this.fields.VacuumCostPerUnit = x.VacuumCostPerUnit ?? 0;
          this.fields.LacquerCostPerUnit = x.LacquerCostPerUnit ?? 0;
          this.fields.InlineScraping = x.InlineScraping ?? 0;
          this.fields.TumblingCostPerUnit = x.TumblingCostPerUnit ?? 0;
          this.fields.FinishingCostLm = x.FinishingCostLm ?? 0;
          this.fields.RmcostLm = x.RmcostLm ?? 0;
          this.fields.OverheadCost = x.OverheadCost ?? 0;
          this.fields.PackagingCostPerLm = x.PackagingCostPerUnit ?? 0;
          this.fields.MiscellaneousCostPerUnit = x.MiscellaneousCostPerUnit ?? 0;
          this.fields.TotalCostLm = x.TotalCostLm ?? 0;
          this.fields.FabricCost = x.FabricCost ?? 0;
          this.fields.FinalTotalCost = x.FinalTotalCost ?? 0;
          this.fields.ProfitLossLm = x.ProfitLossLm ?? 0;
          this.fields.TotalProfitLoss = x.TotalProfitLoss ?? 0;
          this.fields.CostingStatus = x.CostingStatus ?? '';
          this.fields.AddedBy = x.AddedBy ?? '';
          this.fields.AddedDate = new DatetimeConverter().transform(x.AddedDate) ?? '';
          this.fields.ProductionCompletedDate = new DatetimeConverter().transform(x.ProductionCompletedDate) ?? '';
          this.exportfields.push(this.fields);
        });
        this.totalItemsCount = count;
        this.isTableLoading = false;
      },
      error: res => {
        this.count++
        if (this.count < 2) {
          this.GetFilteredCosting();
        }
      }
    })
  }
  onSearch() {
    this.GetFilteredCosting();

  }

  OpenViewPop(data: CostingModel) {
    this.PerLmData = null;
    this.isPerMtrDetailsVisible = true;
    this.PerLmData = data;
  }
  handleRMDetailsCancel() {
    this.isPerMtrDetailsVisible = false;
  }
  onKeydown(event: any) {
    if (
      (event.target.selectionStart === 0 && event.code === 'Space') ||
      (event.key === 'Enter' && event.keyCode === 13)
    ) {

      event.preventDefault();
      //event = this.search();
    }
  }

  reset(): void {
    this.searchValue = '';
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  GetAllCustomer() {
    this.loader.show();
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe({
      next: res => {
        this.CustomerList = res;
        this.loader.hide();
      },
      error: res => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) { this.GetAllCustomer(); }
      }
    });
  }
  GetAllFormulationCode() {
    this.loader.show();
    let url = this.ApiUrl + "saleorder/getallformulationcodesforfilter";
    this.http.get<FormulationCodeModel[]>(url).subscribe({
      next: res => {
        this.FormulationCodeList = res;
        this.loader.hide();
      },
      error: res => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) { this.GetAllFormulationCode(); }
      }
    });
  }
  GetAllColor() {
    this.loader.show();
    let url = this.ApiUrl + "Color/getallColors";
    this.http.get<ColorModel[]>(url).subscribe({
      next: res => {
        this.ColorList = res;
        this.loader.hide();
      },
      error: res => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.GetAllColor();
        }
      }
    });
  }
  GetAllGrain() {
    this.loader.show();
    let url = this.ApiUrl + "Grain/getallGrains";
    this.http.get<GrainModel[]>(url).subscribe({
      next: res => {
        this.GrainList = res;
        this.loader.hide();
      }, error: res => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.GetAllGrain();
        }
      }
    });
  }
  GetAllThickness() {
    let url = this.ApiUrl + "thickness/getallthicknessdata";
    this.http.get<ThicknessModel[]>(url).subscribe(res => {
      this.ThicknessList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllThickness()
      }
    });
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllProductCategory();
      this.GetAllCustomer();
      this.GetAllFormulationCode();
      this.GetAllColor();
      this.GetAllGrain();
      this.GetAllThickness();
    }
  }
  OpenCostingPop(data: any) {
    if (data.CostingStatus == 'Pending') {
      this.alertService.warning("Raw Materials Consumption is not completed till now for this order.");
    }
    else {
      // this.loader.show();

      // this.isVisibleCosting = true;
      // this.GetPostProcessCosting();
      // this.GetSaleOrderDetails(parseInt(data.SaleOrderId))
      var cost = new SaleOrderCostingParamsModel();
      cost.SaleOrderId = parseInt(data.SaleOrderId);
      cost.IsDataOnly = false;
      cost.GSMType = 'costing';

      this.CostingView.SaleOrderCostingViewParams = cost;
      this.CostingView.show();
    }
  }
  GetPostProcessCosting() {
    let url = this.ApiUrl + "saleorder/getpostprocesscosting";
    this.http.get<PostProcessCostingMasterModel[]>(url).subscribe(res => {
      this.PostProcessCostingList = res;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetPostProcessCosting();
      }
    });
  }



  savecosting(): void {
    let soc = new SaleOrderCostingModel();
    soc.SaleOrderId = this.costingService.Orderlist.SaleOrderId;
    soc.FabricCost = this.costingService.FabricProductCostPerLm * this.costingService.Orderlist.SaleOrderProduction.ManufacturingQuantity;
    soc.FabricCostLm = this.costingService.FabricProductCostPerLm;
    soc.PasteCostLm = this.costingService.TotalCostLm;
    soc.GrainCostLm = this.costingService.Orderlist.SaleOrderProduction.GrainPrice;
    soc.FinishingCostLm = this.costingService.calculatefinishingcost();
    soc.RmcostLm = this.costingService.calculateRMCost();
    soc.InlineScraping = this.costingService.InlineScraping;
    soc.Rejection = this.costingService.RejectionConstant;
    soc.ProductionCostLm = this.costingService.ProductionCostLm;
    soc.PerLmconstant = this.costingService.PerLMConstant;
    soc.OverheadCost = this.costingService.OverheadCost;
    soc.SaleOrderMaterialType = this.costingService.MaterialType;
    soc.PrintCostPerUnit = this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null ?
      this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost : 0;
    soc.EmbossingCostPerUnit = this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null ?
      this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost : 0;
    soc.TumblingCostPerUnit = this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null ?
      this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost : 0;
    soc.VacuumCostPerUnit = this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null ?
      this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost : 0;
    soc.LacquerCostPerUnit = this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null ?
      this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost : 0;
    this.loader.show();
    let url = this.ApiUrl + 'costing/addupdatecosting';
    this.http.post<any>(url, soc).subscribe({
      next: (res) => {
        this.alertService.success(res);
        this.Orderlist.SaleOrderCosting = soc;
        this.GetFilteredCosting();
        this.loader.hide();
      },
      error: (res) => {
        this.alertService.error(res.error.ResponseBody);
        this.loader.hide();
      },
    });
  }
  GetSaleOrderTagColor(data: any) {
    if (data.CostingStatus == "Partial Ready") {
      return 'yellow'
    }
    if (data.CostingStatus == "Ready") {
      return 'orange'
    }
    else if (data.CostingStatus == "Submitted") {
      return 'green'
    }
    else
      return 'blue'

  }
  CalculateTotal(column: string) {
    var total = 0;
    switch (column) {
      case column = "SaleOrderQuantity": {
        total = this.CostingListOriginal?.reduce((sum, current) => sum + parseFloat(current.SaleOrderProduction.OrderQuantity?.toString()), 0) ?? 0
        break;
      }
      case column = "ManufacturingQuantity": {
        total = this.CostingListOriginal?.reduce((sum, current) => sum + parseFloat(current.SaleOrderProduction.ManufacturingQuantity?.toString()), 0) ?? 0
        break;
      }
      case column = "TotalProfitLoss": {
        total = this.CostingListOriginal?.reduce((sum, current) => sum + (current.TotalProfitLoss ? parseFloat(current.TotalProfitLoss?.toString()) : 0), 0)
        break;
      }
      case column = "FinalTotalCost": {
        total = this.CostingListOriginal?.reduce((sum, current) => sum + (current.FinalTotalCost ? parseFloat(current.FinalTotalCost?.toString()) : 0), 0)
        break;
      }
    }
    return parseFloat(total?.toFixed(2));
  }
  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllProductCategory(); }
    });

  }
  PrintPage(SaleOrderId: number) {
    const tempOrderId = SaleOrderId
    const printUrl = `/ordercostingprint/${tempOrderId}/costing`;
    window.open(printUrl, '_blank');
  }
  PrintPageStandardGSM(SaleOrderId: number) {
    const tempOrderId = SaleOrderId
    const printUrl = `/ordercostingprint/${tempOrderId}/standard`;
    window.open(printUrl, '_blank');
  }
  getDateRange(label: string) {
    this.enableCustomDateRange = false;
    this.enableWorkShiftDropDown = false;
    this.request.WorkShift = '';
    const today = new Date();
    let startDate: Date;
    let endDate: Date;

    switch (label) {
      case 'dayshift':
        startDate = new Date(new Date(new Date().setHours(8, 0, 0)))
        endDate = new Date(new Date(new Date().setHours(20, 0, 0)))
        this.request.WorkShift = 'day'
        break;

      case 'nightshift':
        var currentHour = parseInt(new Date().getHours().toString());
        if (currentHour >= 0 && currentHour <= 8) {
          startDate = new Date(new Date(new Date().setHours(20, 0, 0)).setDate(new Date().getDate() - 1))
          endDate = new Date(new Date().setHours(8, 0, 0))
        }
        else {
          startDate = new Date(new Date().setHours(20, 0, 0))
          endDate = new Date(new Date(new Date().setHours(8, 0, 0)).setDate(new Date().getDate() + 1))
        }
        this.request.WorkShift = 'night'
        break;

      case 'today':
        startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(today.getDate()));
        endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
        this.enableWorkShiftDropDown = true;
        break;

      case 'yesterday':
        startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 1));
        // startDate.setDate(today.getDate() - 1);
        endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(new Date().getDate() - 1));
        // endDate.setDate(today.getDate() - 1);
        this.enableWorkShiftDropDown = true;
        break;

      case 'last7days':
        // startDate = new Date(today);
        // startDate.setDate(today.getDate() - 6);
        // endDate = new Date(today);
        startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 6));
        // startDate.setDate(today.getDate() - 1);
        endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
        // endDate.setDate(today.getDate() - 1);
        this.enableWorkShiftDropDown = true;
        break;

      case 'last30days':
        startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 29));
        endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
        this.enableWorkShiftDropDown = true;
        break;

      case 'lastmonth':
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
        this.enableWorkShiftDropDown = true;
        break;

      case 'lastyear':
        startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate(), 0, 0, 0);
        endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
        this.enableWorkShiftDropDown = true;
        break;

      case 'custom':
        startDate = new Date(today);
        endDate = new Date(today);
        this.enableCustomDateRange = true;
        this.enableWorkShiftDropDown = true;
        break;

      default:
        startDate = new Date();
        endDate = new Date();
        break;
    }

    if (this.request.DateType == 'submitteddate') {
      this.request.FromAddedDate = startDate;
      this.request.ToAddedDate = endDate;
    }
    else if (this.request.DateType == 'saleorderdate') {
      this.request.FromSaleOrderDate = startDate;
      this.request.ToSaleOrderDate = endDate;
    }
    else if (this.request.DateType == 'deliverydate') {
      this.request.FromDeliveryDate = startDate;
      this.request.ToDeliveryDate = endDate;
    }
    else if (this.request.DateType == 'productiondate') {
      this.request.FromProductionDate = startDate;
      this.request.ToProductionDate = endDate;
    }
    else if (this.request.DateType == 'finalinspectiondate') {
      this.request.FromFinalInspectionDate = startDate;
      this.request.ToFinalInspectionDate = endDate;
    }
  }
}
