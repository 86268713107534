import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { environment } from "../../environments/environment";
import { PurchaseOrderModel, PurchaseOrderProductModel } from "../Models/PurchaseOrderModel";
import { SoDrawerService } from "../Services/SoDrawerService";
import { SaleOrderModel } from "../Models/SalesOrderModel";
import { AuthService } from "../Services/auth.service";
import { Modules, Responsibility } from "../Models/Enums";
import { LoadingService } from "../Services/loadingService";

@Component({
    selector: 'app-SoDrawer',
    templateUrl: './SoDrawer.component.html'
})

export class SoDrawerComponent {
    ApiUrl = environment.Api_Url;
    IsPopupOpen = this.loader.loading$;
    IsDisabled: boolean = false;
    isTableLoading: boolean = false;
    count: 0;
    permission = {
        SaleOrderSalePriceView: false,
        Add: false,
        Delete: false,
        Manage: false
    }
    Poid: any;
    PurchaseOrder: PurchaseOrderModel | undefined;
    isPurchaseOrderLoaded = false;
    refreshBtnLoading: boolean;
    IsTimelineOpen = false;
    postProcessArray: any = [];
    Orderlist!: SaleOrderModel;
    isVisible = false;
    FabricName: string;
    FabricColorName: string;
    TotalCostPerLm: number = 0;
    constructor(
        private loader: SoDrawerService,
        public http: HttpClient,
        private auth: AuthService,
        private loadsrv: LoadingService
    ) {
        this.loader.event_callback.subscribe(x => {
            this.showDrawerData(x);
        })
    }
    ngOnInit() {
        this.permission.SaleOrderSalePriceView = this.auth.CheckResponsibility(Modules.SalesOrderPrice, Responsibility.View);
        if (!this.permission.SaleOrderSalePriceView) {
            this.permission.SaleOrderSalePriceView = this.auth.CheckResponsibility(Modules.Costing, Responsibility.View)
        }
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    showDrawerData(SaleOrderId: string) {
        this.isTableLoading = true;
        this.loadsrv.show();
        let url =
            this.ApiUrl + 'saleorder/getsaleorderdataforviewbyid/' + SaleOrderId;
        this.postProcessArray = [];
        this.http.get<any>(url).subscribe((res) => {
            this.Orderlist = res;
            if (this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint.length > 0) {
                for (let childElement of this.Orderlist.SaleOrderProduction?.SaleOrderProductionPrint) {
                    if (childElement.Rank) {
                        this.postProcessArray.push({ PostProcessName: this.Orderlist.SaleOrderPostProcessOrder.find(x => x.Rank == childElement.Rank).PostProcessName, Rank: childElement.Rank, Name: childElement.Name, Code: childElement.Code, Removed: childElement.Removed, RemovedBy: childElement.RemovedBy, RemovedDate: childElement.RemovedDate });
                    }
                }
            }
            if (this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing.length > 0) {
                for (let childElement of this.Orderlist.SaleOrderProduction?.SaleOrderProductionEmbossing) {
                    if (childElement.Rank) {
                        this.postProcessArray.push({ PostProcessName: this.Orderlist.SaleOrderPostProcessOrder.find(x => x.Rank == childElement.Rank).PostProcessName, Rank: childElement.Rank, Name: childElement.Name, Code: childElement.Code, Removed: childElement.Removed, RemovedBy: childElement.RemovedBy, RemovedDate: childElement.RemovedDate });
                    }
                }
            }
            if (this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum.length > 0) {
                for (let childElement of this.Orderlist.SaleOrderProduction?.SaleOrderProductionVacuum) {
                    if (childElement.Rank) {
                        this.postProcessArray.push({ PostProcessName: this.Orderlist.SaleOrderPostProcessOrder.find(x => x.Rank == childElement.Rank).PostProcessName, Rank: childElement.Rank, Name: childElement.Name, Code: childElement.Code, Removed: childElement.Removed, RemovedBy: childElement.RemovedBy, RemovedDate: childElement.RemovedDate });
                    }
                }
            }
            if (this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling.length > 0) {
                for (let childElement of this.Orderlist.SaleOrderProduction?.SaleOrderProductionTumbling) {
                    if (childElement.Rank) {
                        this.postProcessArray.push({ PostProcessName: this.Orderlist.SaleOrderPostProcessOrder.find(x => x.Rank == childElement.Rank).PostProcessName, Rank: childElement.Rank, Name: childElement.Name, Code: childElement.Code, Removed: childElement.Removed, RemovedBy: childElement.RemovedBy, RemovedDate: childElement.RemovedDate });
                    }
                }
            }
            if (this.Orderlist.SaleOrderProduction?.Lacquer.length > 0) {
                for (let childElement of this.Orderlist.SaleOrderProduction?.Lacquer) {
                    if (childElement.Rank) {
                        this.postProcessArray.push({ PostProcessName: this.Orderlist.SaleOrderPostProcessOrder.find(x => x.Rank == childElement.Rank).PostProcessName, Rank: childElement.Rank, Name: childElement.Name, Code: childElement.Code, Removed: childElement.Removed, RemovedBy: childElement.RemovedBy, RemovedDate: childElement.RemovedDate });
                    }
                }
            }
            this.postProcessArray = this.postProcessArray.length > 0 ? this.postProcessArray.sort((a, b) => a.Rank - b.Rank) : [];
            this.isVisible = true;
            this.calclulateTotalSalePrice();
            this.FabricName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.FormulationFabricProductName;
            this.FabricColorName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.SaleOrderProduction.FabricColorName;
            this.loadsrv.hide();
        });

    }
    calclulateTotalSalePrice() {
        this.Orderlist.SaleOrderProduction.TotalSalePrice = parseFloat(((isNaN(this.Orderlist.SaleOrderProduction.OrderQuantity) ? 0 : this.Orderlist.SaleOrderProduction.OrderQuantity) * (isNaN(this.Orderlist.SaleOrderProduction.SalePrice) ? 0 : this.Orderlist.SaleOrderProduction.SalePrice)).toString()).toFixed(2)
    }
    calculateTotalProfitLoss() {
        return (parseFloat(this.Orderlist.SaleOrderProduction.TotalSalePrice) - this.TotalCostPerLm * this.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)
    }
}