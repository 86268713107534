import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SaleOrderCostingModel, SaleOrderCostingViewModel, SaleOrderProductionMixingRawMaterialModel } from '../Models/SalesOrderModel';
import { PostProcessCostingMasterModel } from '../Models/MasterModel';
import { LoadingService } from './loadingService';
import { AlertMessageService } from './AlertMessageService';
import { SaleOrderCostingService } from './SaleOrderCostingService';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SaleOrderCostingDataService {
  getToken: any;
  ApiUrl = environment.Api_Url;

  InlineScraping: number;
  havePostProcess: boolean;
  Orderlist!: SaleOrderCostingViewModel;
  FabricName: string;
  FabricColorName: string;
  RejectionConstant: number = 6;
  OverheadCost: number = 25;
  PerLMConstant: number = 1.45;
  ProductionCostLm: number = 0;
  TotalCostPerLm: number = 0;
  TotalCoating: number = 0;
  TotalFinalGSM: number = 0;
  TotalCostLm: number = 0;
  TotalLaquerPrice: number = 0;
  Rejection: number = 0;
  FabricProductCostPerLm: number = 0;
  FabricKgsToMtrQuantity: number = 0;
  MaterialType: string = 'PVC';
  PostProcessCostingList: PostProcessCostingMasterModel[];
  IsCostingVisible: boolean;
  TotalProductionQty: number = 0;
  FabricQTYFromKGToMTR: number;
  count: number;
  FabricFinalAvgGsm: number = 0;

  PackagingTotalQuantity: number = 0;
  PackagingTotalCost: number = 0;
  PackagingCostPerLm: number = 0;

  MiscTotalQuantity: number = 0;
  MiscTotalCost: number = 0;
  MiscCostPerLm: number = 0;
  loadingCompleted: boolean = false;
  IsCostingSaved: boolean = false;

  constructor(private http: HttpClient, private loader: LoadingService,
    private alertService: AlertMessageService, private SaleOrderCostingService: SaleOrderCostingService,) { }

  GetSaleOrderDetails(SaleOrderId: number, GSMType = ""): Observable<boolean> {
    this.loader.show();
    this.InlineScraping = 0;
    this.IsCostingSaved = false;
    this.havePostProcess = false
    let url = this.ApiUrl + "saleorder/getsaleordercostingviewdatabyid/" + SaleOrderId;
    this.http.get<any>(url).subscribe(res => {

      this.Orderlist = res;
      this.calclulateTotalSalePrice()
      this.FabricName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.FormulationFabricProductName;
      this.FabricColorName = this.Orderlist.FormulationFabricProductId == null || this.Orderlist.FormulationFabricProductId == 0 ? "N/A" : this.Orderlist.SaleOrderProduction.FabricColorName;
      if (this.Orderlist.SaleOrderCosting != null) {
        this.RejectionConstant = this.Orderlist.SaleOrderCosting.Rejection;
        this.InlineScraping = this.Orderlist.SaleOrderCosting.InlineScraping;
        this.PerLMConstant = this.Orderlist.SaleOrderCosting.PerLmconstant;
        this.OverheadCost = this.Orderlist.SaleOrderCosting.OverheadCost;
        this.IsCostingSaved = true;
      }
      else {
        this.RejectionConstant = this.Orderlist.SaleOrderProduction.RejectionPercentAutoCalculated;
        this.PerLMConstant = this.Orderlist.SaleOrderProduction.LMConstant;
        this.OverheadCost = this.Orderlist.SaleOrderProductionComplete == null ? 25 : this.Orderlist.SaleOrderProductionComplete.Overhead;
        this.calclulateProductinCostPerLm(this.OverheadCost);
      }

      if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null || this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null ||
        this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null || this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null ||
        this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null) {
        this.havePostProcess = true;
      }

      this.Orderlist.SaleOrderProduction.MixingGSMTotal = this.Orderlist.SaleOrderProduction.MixingTotalCost = 0;
      // this.Orderlist.SaleOrderProduction.FormulationMixing.forEach(element => {
      //   this.Orderlist.SaleOrderProduction.MixingTotalCost += isNaN(element.Total) ? 0 : element.Total;
      //   this.Orderlist.SaleOrderProduction.MixingGSMTotal += isNaN(parseInt(element.WeightGsm?.toString())) ? 0 : parseInt(element.WeightGsm.toString());
      // })
      this.TotalCostLm = 0
      this.TotalCoating = 0
      this.Orderlist.SaleOrderProduction.FinalFormulationMixing.forEach(element => {
        if (GSMType == 'costing') {
          var _extraGSM = parseFloat((element.FinalGSM - element.GSM).toFixed(3))
          element.ExtraGSM = _extraGSM > 0 ? Math.abs(_extraGSM) : 0
          element.LessGSM = _extraGSM < 0 ? Math.abs(_extraGSM) : 0
          if (element.MixingName != "PRE SKIN") {
            this.TotalCoating += element.FinalGSM;
          }
        }
        else if (GSMType == 'standard') {
          if (element.MixingName != "PRE SKIN") {
            this.TotalCoating += element.GSM;
          }
        }
        this.TotalCoating = parseFloat(this.TotalCoating.toFixed(2));
        element.TotalQuantity = parseFloat((element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0)).toFixed(2)) ?? 0
        element.TotalScQuantity = parseFloat((element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Scquantity?.toFixed(2)), 0)).toFixed(2)) ?? 0
        element.TotalCost = parseFloat(element.MixingRawMaterial.reduce((sum, current) => sum + (parseFloat(current.Price?.toFixed(2)) == 0 ? 0 : parseFloat(current.Price?.toFixed(2)) * parseFloat(current.Quantity?.toFixed(2))), 0).toFixed(2)) ?? 0
        let clm = 0;
        if (GSMType == 'costing') {
          clm = ((element.TotalCost / element.TotalQuantity / 1000) * this.PerLMConstant) * element.FinalGSM;
        }
        else if (GSMType == 'standard') {
          clm = ((element.TotalCost / element.TotalQuantity / 1000) * this.PerLMConstant) * element.GSM;
        }
        element.CostPerLm = isNaN(clm) ? 0 : parseFloat(clm.toFixed(2));
        this.TotalCostLm = parseFloat((this.TotalCostLm + element.CostPerLm).toFixed(2));
      });

      this.Orderlist.SaleOrderProduction.FinalFabricData?.forEach(element => {
        // Calculate total for Mtrs
        this.calculateAverageGsm(element.MixingRawMaterial);
        this.FabricProductCostPerLm = element.TotalQuantity > 0 ? parseFloat((element.PricePerMtr).toFixed(2)) : 0;
      });
      this.PackagingTotalQuantity = this.Orderlist.SaleOrderProduction.PackagingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0) ?? 0
      this.PackagingTotalCost = this.Orderlist.SaleOrderProduction.PackagingRawMaterial.reduce((sum, current) => sum + parseFloat((current.Price * current.Quantity).toFixed(2)), 0) ?? 0
      this.PackagingCostPerLm = this.PackagingTotalQuantity > 0 ? parseFloat((this.PackagingTotalCost / this.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)) : 0;

      this.MiscTotalQuantity = this.Orderlist.SaleOrderProduction.SaleOrderProductionMiscellaneousRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0) ?? 0;
      this.MiscTotalCost = this.Orderlist.SaleOrderProduction.SaleOrderProductionMiscellaneousRawMaterial.reduce((sum, current) => sum + parseFloat((current.Price * current.Quantity).toFixed(2)), 0) ?? 0;
      this.MiscCostPerLm = parseFloat((this.MiscTotalCost / this.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)) ?? 0;

      this.TotalCostLm = 0;

      this.Orderlist.SaleOrderProduction.FinalFormulationMixing.forEach(element => {

        element.TotalQuantity = parseFloat((element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0)).toFixed(2)) ?? 0
        element.TotalScQuantity = parseFloat((element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Scquantity?.toFixed(2)), 0)).toFixed(2)) ?? 0
        element.TotalCost = parseFloat(element.MixingRawMaterial.reduce((sum, current) => sum + (parseFloat(current.Price?.toFixed(2)) == 0 ? 0 : parseFloat(current.Price?.toFixed(2)) * parseFloat(current.Quantity?.toFixed(2))), 0).toFixed(2)) ?? 0
        let clm = 0;
        if (GSMType == 'costing') {
          clm = ((element.TotalCost / element.TotalQuantity / 1000) * this.PerLMConstant) * element.FinalGSM;
        }
        else if (GSMType == 'standard') {
          clm = ((element.TotalCost / element.TotalQuantity / 1000) * this.PerLMConstant) * element.GSM;
        }
        element.CostPerLm = isNaN(clm) ? 0 : parseFloat(clm.toFixed(2));
        this.TotalCostLm = parseFloat((this.TotalCostLm + element.CostPerLm).toFixed(2));
      })

      // this.TotalLaquerPrice = 0
      // this.Orderlist.SaleOrderProduction.Lacquer.forEach(element => {
      //   element.TotalQuantity = parseFloat((element.LacquerRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0)).toFixed(2)) ?? 0;
      //   element.TotalPerUnitPrice = parseFloat(element.LacquerRawMaterial.reduce((sum, current) => sum + (parseFloat(current.Price?.toFixed(2)) == 0 ? 0 : parseFloat(current.Price?.toFixed(2)) * parseFloat(current.Quantity?.toFixed(2))), 0).toFixed(2)) ?? 0;
      //   this.TotalLaquerPrice = this.TotalLaquerPrice + element.TotalPerUnitPrice;
      // });
      // if (this.Orderlist.FormulationFabricProductId != null) {
      //   this.calculateFabricProductCostPerLm();
      // }
      this.calclulatePostProesscost();
      let finishcost = 0;
      finishcost = this.calculatefinishingcost();
      this.Rejection = parseFloat(((this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice + finishcost) * (this.RejectionConstant / 100)).toFixed(2));
      this.MaterialType = this.GetProductType(this.Orderlist.SaleFormulationCode)
      this.ProductionCostLm = parseFloat((this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice + this.Rejection + finishcost).toFixed(2));

      this.TotalCostPerLm = parseFloat((this.ProductionCostLm + this.OverheadCost + this.PackagingCostPerLm + this.MiscCostPerLm).toFixed(2));

      this.SetTotalProductionQty()
      this.SaleOrderCostingService.setSaleOrderCosting(this.Orderlist);
      this.loader.hide();
      if (this.SaleOrderCostingService.SaleOrderCostingViewParams?.IsDataOnly == false) {
        this.IsCostingVisible = true;
      }
      // else {
      //     this.SaleOrderCostingService.setSaleOrderCosting(this.Orderlist);
      // }
    });
    return new Observable<boolean>(observer => {
      setTimeout(() => {
        observer.next(true);
        observer.complete();
      }, 100);
    });
  }
  calculateAverageGsm(FabricList: SaleOrderProductionMixingRawMaterialModel[]) {
    let totalGsm = 0;
    let count = 0;

    FabricList.forEach(product => {
      if (product.AvgGsm && !isNaN(product.AvgGsm)) {
        totalGsm += product.AvgGsm;
        count++;
      }
    });

    this.FabricFinalAvgGsm = count > 0 ? totalGsm / count : totalGsm;
  }

  calclulatePostProesscost() {
    if (this.Orderlist.SaleOrderCosting) {
      this.MaterialType = this.Orderlist.SaleOrderCosting?.SaleOrderMaterialType;
    }
    if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null) {
      let perunitcost = this.PostProcessCostingList?.filter(x => x.PostProcessName == "Print" && x.SaleOrderType == this.MaterialType)[0].Cost;
      // this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCompletedQuantity * perunitcost;
      this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost = perunitcost;
    }
    if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null) {
      let perunitcost = this.PostProcessCostingList?.filter(x => x.PostProcessName == "Embossing" && x.SaleOrderType == this.MaterialType)[0].Cost;
      // this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCompletedQuantity * perunitcost;
      this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost = perunitcost;
    }
    if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null) {
      let perunitcost = this.PostProcessCostingList?.filter(x => x.PostProcessName == "Tumbling" && x.SaleOrderType == this.MaterialType)[0].Cost;
      // this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCompletedQuantity * perunitcost;
      this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost = perunitcost;
    }
    if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null) {
      let perunitcost = this.PostProcessCostingList?.filter(x => x.PostProcessName == "Vacuum" && x.SaleOrderType == this.MaterialType)[0].Cost;
      // this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCompletedQuantity * perunitcost;
      this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost = perunitcost;
    }
    if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null) {
      let perunitcost = this.PostProcessCostingList?.filter(x => x.PostProcessName == "Lacquer" && x.SaleOrderType == this.MaterialType)[0].Cost;
      // this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCompletedQuantity * perunitcost;
      this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost = perunitcost;
    }
  }

  Recalculate() {
    this.TotalCostLm = 0;
    this.TotalCoating = 0;
    this.Orderlist.SaleOrderProduction.FinalFormulationMixing.forEach(element => {
      if (element.MixingName != "PRE SKIN") {
        this.TotalCoating += element.FinalGSM;
      }
      this.TotalCoating = parseFloat(this.TotalCoating.toFixed(2));
      element.TotalQuantity = parseFloat((element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0)).toFixed(2)) ?? 0
      element.TotalScQuantity = parseFloat((element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Scquantity?.toFixed(2)), 0)).toFixed(2)) ?? 0
      element.TotalCost = parseFloat(element.MixingRawMaterial.reduce((sum, current) => sum + (parseFloat(current.Price?.toFixed(2)) == 0 ? 0 : parseFloat(current.Price?.toFixed(2)) * parseFloat(current.Quantity?.toFixed(2))), 0).toFixed(2)) ?? 0
      let clm = ((element.TotalCost / element.TotalQuantity / 1000) * this.PerLMConstant) * element.FinalGSM;
      element.CostPerLm = isNaN(clm) ? 0 : parseFloat(clm.toFixed(2));
      this.TotalCostLm = parseFloat((this.TotalCostLm + element.CostPerLm).toFixed(2));
    });

    this.Orderlist.SaleOrderProduction.FinalFabricData?.forEach(element => {
      // Calculate total for Mtrs
      this.calculateAverageGsm(element.MixingRawMaterial);
      this.FabricProductCostPerLm = element.TotalQuantity > 0 ? parseFloat((element.PricePerMtr).toFixed(2)) : 0;
    });

    this.PackagingTotalQuantity = this.Orderlist.SaleOrderProduction.PackagingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0) ?? 0
    this.PackagingTotalCost = this.Orderlist.SaleOrderProduction.PackagingRawMaterial.reduce((sum, current) => sum + parseFloat((current.Price * current.Quantity).toFixed(2)), 0) ?? 0
    this.PackagingCostPerLm = this.PackagingTotalQuantity > 0 ? parseFloat((this.PackagingTotalCost / this.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)) : 0;

    this.MiscTotalQuantity = this.Orderlist.SaleOrderProduction.SaleOrderProductionMiscellaneousRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0) ?? 0;
    this.MiscTotalCost = this.Orderlist.SaleOrderProduction.SaleOrderProductionMiscellaneousRawMaterial.reduce((sum, current) => sum + parseFloat((current.Price * current.Quantity).toFixed(2)), 0) ?? 0;
    this.MiscCostPerLm = parseFloat((this.MiscTotalCost / this.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)) ?? 0;
    this.TotalCostLm = 0;
    this.Orderlist.SaleOrderProduction.FinalFormulationMixing.forEach(element => {

      element.TotalQuantity = parseFloat((element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Quantity?.toFixed(2)), 0)).toFixed(2)) ?? 0
      element.TotalScQuantity = parseFloat((element.MixingRawMaterial.reduce((sum, current) => sum + parseFloat(current.Scquantity?.toFixed(2)), 0)).toFixed(2)) ?? 0
      element.TotalCost = parseFloat(element.MixingRawMaterial.reduce((sum, current) => sum + (parseFloat(current.Price?.toFixed(2)) == 0 ? 0 : parseFloat(current.Price?.toFixed(2)) * parseFloat(current.Quantity?.toFixed(2))), 0).toFixed(2)) ?? 0
      let clm = ((element.TotalCost / element.TotalQuantity / 1000) * this.PerLMConstant) * element.FinalGSM;

      element.CostPerLm = isNaN(clm) ? 0 : parseFloat(clm.toFixed(2));
      this.TotalCostLm = parseFloat((this.TotalCostLm + element.CostPerLm).toFixed(2));
    })
    this.calclulatePostProesscost();
    let finishcost = 0;
    finishcost = this.calculatefinishingcost();
    this.Rejection = parseFloat(((this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice + finishcost) * (this.RejectionConstant / 100)).toFixed(2));
    this.ProductionCostLm = parseFloat((this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice + this.Rejection + finishcost).toFixed(2));

    this.TotalCostPerLm = parseFloat((this.ProductionCostLm + this.OverheadCost + this.PackagingCostPerLm + this.MiscCostPerLm).toFixed(2));
  }

  calclulateLMcost(PerLMConstant: number) {
    this.PerLMConstant = PerLMConstant;
    this.Recalculate();
  }
  calculateInline(res: number) {
    this.InlineScraping = res;
    this.Recalculate();
  }
  calculateRMCost() {
    let res = this.calculatefinishingcost();
    res = parseFloat((res + this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice).toFixed(2))
    return res;
  }

  calclulateRejection(rejection: number) {
    this.Rejection = parseFloat(((this.TotalCostLm + this.FabricProductCostPerLm + this.Orderlist.SaleOrderProduction.GrainPrice) * (rejection / 100)).toFixed(2));
    this.RejectionConstant = rejection;
    this.Recalculate();
  }

  calclulateProductinCostPerLm(overheadcost: number) {
    this.TotalCostPerLm = parseFloat((this.ProductionCostLm + overheadcost).toFixed(2))
  }
  calculateFabricProductCostPerLm() {
    if (this.Orderlist.FormulationFabricProductUnit == 'Mtrs') {
      this.FabricProductCostPerLm = this.Orderlist.FormulationFabricProductPrice
    }
    else {
      this.FabricProductCostPerLm = parseFloat((((this.Orderlist.SaleOrderProductionComplete?.FabricGsm / 1000) * this.PerLMConstant) * this.Orderlist.FormulationFabricProductPrice).toFixed(2));
    }
  }

  calculatefinishingcost() {
    let res = 0
    if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null) {
      // res = (this.TotalLaquerPrice / this.Orderlist.SaleOrderProduction.ManufacturingQuantity) + this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost;
      res = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost;
    }
    res = res + this.InlineScraping;
    if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null) {
      res = res + this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost;
    }
    if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null) {
      res = res + this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost;
    }
    if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null) {
      res = res + this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost;
    }
    if (this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null) {
      res = res + this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost;
    }
    return parseFloat(res.toFixed(2));
  }


  savecosting(): void {
    let soc = new SaleOrderCostingModel();
    soc.SaleOrderId = this.Orderlist.SaleOrderId;
    soc.FabricCost = this.FabricProductCostPerLm * this.Orderlist.SaleOrderProduction.ManufacturingQuantity;
    soc.FabricCostLm = this.FabricProductCostPerLm;
    soc.PasteCostLm = this.TotalCostLm;
    soc.GrainCostLm = this.Orderlist.SaleOrderProduction.GrainPrice;
    soc.FinishingCostLm = this.calculatefinishingcost();
    soc.RmcostLm = this.calculateRMCost();
    soc.InlineScraping = this.InlineScraping;
    soc.Rejection = this.RejectionConstant;
    soc.ProductionCostLm = this.ProductionCostLm;
    soc.PerLmconstant = this.PerLMConstant;
    soc.OverheadCost = this.OverheadCost;
    soc.SaleOrderMaterialType = this.MaterialType;
    soc.PrintCostPerUnit = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null ?
      this.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost : 0;
    soc.EmbossingCostPerUnit = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null ?
      this.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost : 0;
    soc.TumblingCostPerUnit = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null ?
      this.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost : 0;
    soc.VacuumCostPerUnit = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null ?
      this.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost : 0;
    soc.LacquerCostPerUnit = this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null ?
      this.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost : 0;
    soc.PackagingCostPerUnit = this.PackagingCostPerLm;
    soc.MiscellaneousCostPerUnit = this.MiscCostPerLm;
    this.loader.show();
    let url = this.ApiUrl + 'costing/addupdatecosting';
    this.http.post<any>(url, soc).subscribe({
      next: (res) => {
        this.alertService.success(res);
        this.Orderlist.SaleOrderCosting = soc;
        // this.GetFilteredCosting();
        this.IsCostingVisible = false;
        this.loader.hide();
      },
      error: (res) => {
        this.alertService.error(res.error.ResponseBody);
        this.IsCostingVisible = false;
        this.loader.hide();
      },
    });
  }
  GetProductType(data: string) {
    if (data.split('-')[0] == "GZ")
      return "PVC"
    else
      return "PU"
  }
  handleCancelCostingPopup() {
    // this.SaleOrderCostingService.hide();
    this.IsCostingVisible = false;
    this.Orderlist = null;
    this.loader.hide();
    this.TotalCoating = 0;
    this.IsCostingSaved = false;
  }
  calclulateTotalSalePrice() {
    this.Orderlist.SaleOrderProduction.TotalSalePrice = parseFloat(((isNaN(this.Orderlist.SaleOrderProduction.OrderQuantity) ? 0 : this.Orderlist.SaleOrderProduction.OrderQuantity) * (isNaN(this.Orderlist.SaleOrderProduction.SalePrice) ? 0 : this.Orderlist.SaleOrderProduction.SalePrice)).toString()).toFixed(2)
  }
  calculateTotalProfitLoss() {
    return ((this.Orderlist.SaleOrderProduction.SalePrice * this.Orderlist.SaleOrderProduction.ManufacturingQuantity) - (this.TotalCostPerLm * this.Orderlist.SaleOrderProduction.ManufacturingQuantity)).toFixed(2)
  }

  SetTotalProductionQty() {
    if (this.Orderlist.SaleOrderProduction.ExtraProduction > 0) {
      this.TotalProductionQty = parseFloat((this.Orderlist.SaleOrderProduction.OrderQuantity + (this.Orderlist.SaleOrderProduction.OrderQuantity * this.Orderlist.SaleOrderProduction.ExtraProduction) / 100).toFixed(2));
    }
    else {
      this.TotalProductionQty = this.Orderlist.SaleOrderProduction.OrderQuantity;
    }
    this.calculateFabricQtyKGtoMTR();
  }
  calculateFabricQtyKGtoMTR() {
    if (this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode?.FabricProductUnit != "Mtrs" && this.Orderlist.SaleOrderProduction.FabricProductId > 0) {
      if (this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricWidthInMeter != null && this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricWidthInMeter > 0 && this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricGsm != null && this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricGsm > 0) {
        this.FabricQTYFromKGToMTR = parseFloat(((this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricProductQty * 1000) / (this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricGsm * this.Orderlist.SaleOrderProduction.InspectionSaleFormulationCode.FabricWidthInMeter)).toFixed(2));
      } else {
        this.FabricQTYFromKGToMTR = this.TotalProductionQty;
      }
    }
  }
  GetPostProcessCosting() {
    let url = this.ApiUrl + "saleorder/getpostprocesscosting";
    this.http.get<PostProcessCostingMasterModel[]>(url).subscribe(res => {
      this.PostProcessCostingList = res;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetPostProcessCosting();
      }
    });
  }
}
