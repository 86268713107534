import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './Home/Home.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { PmsUINzAntModule } from './PmsUIApp-NzAnt.module';
import { en_US } from 'ng-zorro-antd/i18n';
import { DatePipe, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PmsUIAppRoutingModule } from './PmsUIApp-routing.module';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { MyInterceptor } from './Services/HttpInterceptorService';
import { AlertMessageService } from './Services/AlertMessageService';
import { StockListComponent } from './InventoryManagement/StockList/StockList.component';
import { StockDetailsComponent } from './InventoryManagement/StockDetails/StockDetails.component';
import { StockInspectionComponent } from './InventoryManagement/StockInspection/StockInspection.component';
import { StockProductAllocationComponent } from './InventoryManagement/StockProductAllocation/StockProductAllocation.component';
import { StockManageRejectedItemsComponent } from './InventoryManagement/StockManageRejectedItems/StockManageRejectedItems.component';
import { AddStockComponent } from './InventoryManagement/AddStock/AddStock.component';
import { ProductListComponent } from './Product/ProductList/ProductList.component';
import { AuthComponent } from './Authentication/auth/auth.component';
import { LogoutComponent } from './Authentication/logout/logout.component';
import { IndexComponent } from './index/index.component';
import { SupplierListComponent } from './SupplierManagement/Supplier/supplier-list/supplier-list.component';
import { GateInComponent } from './GateManagement/GateIn/GateIn.component';
import { GatePassComponent } from './GateManagement/GatePass/GatePass.component';
import { GateOutComponent } from './GateManagement/GateOut/GateOut.component';
import { TransportManagementComponent } from './transportManagement/transportManagement.component';
import {
  BLOB_STORAGE_TOKEN,
  IAzureStorage,
  IBlobStorage,
} from './azure-storage/azureStorage';
import { BlobStorageService } from './azure-storage/blob-storage.service';
import { NzQRCodeModule } from 'ng-zorro-antd/qr-code';
import { BranchComponent } from './Master/branch/branch.component';
import { DepartmentComponent } from './Master/department/department.component';
import { TagComponent } from './Master/tag/tag.component';
import { StoreComponent } from './Master/store/store.component';
import { RackComponent } from './Master/rack/rack.component';
import { ProductCategoryComponent } from './Master/product-category/product-category.component';
import { ProductFirstSubCategoryComponent } from './Master/product-first-sub-category/product-first-sub-category.component';
import { ProductSecSubCategoryComponent } from './Master/product-sec-sub-category/product-sec-sub-category.component';
import { PaymentTermComponent } from './Master/payment-term/payment-term.component';
import { DeliveryTermComponent } from './Master/delivery-term/delivery-term.component';
import { PurchaseorderComponent } from './PurchaseOrder/Add/purchaseorder.component';
import { POListComponent } from './PurchaseOrder/List/polist.component';
import { MeasurementConversionComponent } from './Master/measurement-conversion/measurement-conversion.component';
import { ColorComponent } from './Master/color/color.component';
import { ElementComponent } from './Master/element/element.component';
import { GrainComponent } from './Master/grain/grain.component';
import { CustomerListComponent } from './CustomerManagement/customer-list/customer-list.component';

import { IssueComponent } from './Issue/Add/Issue.component';

import { IssueListComponent } from './Issue/List/Issuelist.component';
import { MsalModule, MSAL_INSTANCE, MsalService, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalGuard, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalInterceptorConfiguration, MsalInterceptor, MsalRedirectComponent } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { ActiveX } from '../environments/environment';
import { AdminComponent } from './Admin/Users/Admin.component';
import { AppConfigService } from './Authentication/configService';
import { WINDOW_PROVIDERS } from '../window.providers';
import { CostingComponent } from './Costing/Add/Costing.component';
import { CostingListComponent } from './Costing/List/Costinglist.component';
import { ProcessComponent } from './Process/Add/process.component';
import { ProcessListComponent } from './Process/List/processlist.component';
import { MixingComponent } from './Mixing/Add/mixing.component';
import { MixingListComponent } from './Mixing/List/mixinglist.component';
import {
  CallbackPipe,
  DatetimeConverter,
} from './Services/DatetimeConverter.pipe';

import { GatepassprintComponent } from './GateManagement/GatePassPrint/gatepassprint.component';
import { POprintComponent } from './PurchaseOrder/Print/poprint.component';
import { POEmailComponent } from './PurchaseOrder/Email/poemail.component';
import { RoundOff } from './Services/RoundOff.pipe';
import { EmbossingListComponent } from './Production/Post Process/embossing-list/embossing-list.component';
import { TumblingListComponent } from './Production/Post Process/tumbling-list/tumbling-list.component';
import { VaccumListComponent } from './Production/Post Process/vaccum-list/vaccum-list.component';
import { LacquerListComponent } from './Production/Post Process/lacquer-list/lacquer-list.component';
import { PostprocessprintComponent } from './Production/Post Process/postprocessprint/postprocessprint.component';
import { MixingprintComponent } from './Production/mixingprint/mixingprint.component';
import { ProcessprintComponent } from './Production/processprint/processprint.component';

import { OpeningStockComponent } from './InventoryManagement/OpeningStock/OpeningStock.component';
import { StockComponent } from './InventoryManagement/stock/stock.component';

import { StartscreenComponent } from './startscreen/startscreen.component';

import { NewmixingComponent } from './Production/newmixing/newmixing.component';
import { ThicknessComponent } from './Master/thickness/thickness.component';
import { LoaderComponent } from './Loader/loader.component';
import { LoadingService } from './Services/loadingService';
import { WidthComponent } from './Master/width/width.component';

import { ConsumptionListComponent } from './Consumption/List/consumptionlist.component';
import { ConsumptionComponent } from './Consumption/Add/consumption.component';
import { ApiHttpService } from './Services/ApiHttpService';
import { ResponsibilityMasterComponent } from './Admin/ResponsibilityMaster/ResponsibilityMaster.component';
import { RolesMasterComponent } from './Admin/RolesMaster/RolesMaster.component';
import { ProductWiseStockComponent } from './InventoryManagement/Product-wise-stock/Product-wise-stock.component';
import { StoreWiseStockComponent } from './InventoryManagement/StorewiseStock/StoreWiseStock';

import { PostprocessComponent } from './Production/Post Process/PostProcess/postprocess.component';
import { DispatchComponent } from './Production/Dispatch/dispatch.component';

import { FinalInspectionComponent } from './Production/FinalInspection/Add/FinalInspection.component';
import { OutPassPrintComponent } from './OutPass/outpassPrint/outpassprint.component';
import { OutPassAddComponent } from './OutPass/Add/outpassAdd.component';
import { OutPassListComponent } from './OutPass/list/outpasslist.component';

import { StartProductionComponent } from './Production/StartProduction/start-production.component';

import { WorkplanListComponent } from './Production/workplan-list/workplan-list.component';
import { InspectionPrintComponent } from './Production/WorkPlan/inspectionprint/inspectionprint.component';
import { JumboListComponent } from './Production/WorkPlan/Jumbolist/jumbolist.componrnt';
import { JumboMasterComponent } from './Production/WorkPlan/JumboMaster/jumbomaster.component';

import { FormulationCodeListComponent } from './Production/FormulationCode/List/formulation-list.component';
import { FormulationcodeComponent } from './Production/FormulationCode/Add/newformulationcode.component';
import { MeasurementUnitComponent } from './Master/measurement-unit/measurement-unit.component';
import { WithSupplierProductWiseStockReportComponent } from './Reports/StockReports/WithSupplierProductWiseStock/WithSupplierProductWiseStock.component';
import { proformaAddComponent } from './SalesOrder/proformaInvoice/Add/proformaAdd.component';
import { proformaListComponent } from './SalesOrder/proformaInvoice/List/proformalist.component';
import { proformaPrintComponent } from './SalesOrder/proformaInvoice/Print/proformaPrint.component';
import { PurchaseReportComponent } from './Reports/PurchaseReports/PurchaseReport/PurchaseReport.component';
import { SaleslistComponent } from './SalesOrder/SalesOrder/List/saleslist.component';
import { PresalesorderComponent } from './SalesOrder/SalesOrder/Add/presalesorder.component';
import { ProductStockHistoryReportComponent } from './Reports/ProductStockHistory/ProductStockHistory.component';
import { BankDetailsComponent } from './Master/BankDetails/bankDetails.component';
import { CategoryWiseStock } from './Reports/StockReports/CategoryWiseStock/CategoryWiseStock.component';
import { UsersLoginHistoryComponent } from './Admin/UsersLoginHistory/UsersLoginHistory.component';
import { DemandPopService } from './Services/DemandPopService';
import { DemandComponent } from './Demand/demand.component';
import { DemandListComponent } from './PurchaseOrder/DemandList/DemandList.component';
import { YieldReportComponent } from './Reports/YieldReport/YieldReport.component';
import { WastageReportComponent } from './Reports/WastageReport/WastageReport.component';
import { ProductionPlanningReportComponent } from './Reports/ProductionPlanningReport/ProductionPlanningReport.component';
import { PostProcessReportComponent } from './Reports/PostProcessReport/PostProcessReport.component';
import { ProductStockSummaryReportComponent } from './Reports/ProductStockSummary/ProductStockSummary.component';

import { InspectionAllPrintComponent } from './Production/WorkPlan/inspectionallprint/inspectionallprint.component';
import { PackagingComponent } from './Dispatch/Packaging/Add/Packaging.component';
import { PackagingListComponent } from './Dispatch/Packaging/List/PackagingList.component';
import { PackingListPrintComponent } from './Dispatch/Packaging/Print/PackingListPrint.component';
import { NgxPrintModule } from 'ngx-print';
import { MBFormulationComponent } from './Production/PigmentMB/Add/MBFormulation.component';
import { FinalInspectionList } from './Production/FinalInspection/List/FinalInspectionList.component';
import { ActivityLogComponent } from './Production/ActivityLog/ActivityLog.component';
import { LinkSaleOrderComponent } from './Production/Post Process/LinkSaleOrder/LinkSaleOrder.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { ProductionStatusReportComponent } from './Reports/ProductionStatusReport/ProductionStatusReport.component';
import { GeneralConfigurationComponent } from './Admin/GeneralConfiguration/GeneralConfiguration.component';
import { TimelineService } from './Services/TimelineService';
import { TimelineComponent } from './Timeline/Timeline.component';
import { CostingListPrintComponent } from './Costing/CostingListPrint/CostingListPrint.component';
import { ConsumptionPendingListComponent } from './Consumption/PendingList/pendinglist.component';
import { SalesReportComponent } from './Reports/SalesReport/SalesReport.component';
import { ConsumptionReportComponent } from './Reports/StockReports/ConsumptionReport/consumptionreport.component';
import { InspectionDetailsPrintComponent } from './Production/WorkPlan/InspectionDetailsPrint/InspectionDetailsPrint.component';
import { PackagingListEmailComponent } from './Dispatch/Packaging/Email/PackagingListEmail.component';
import { PasteConsumptionReportComponent } from './Reports/PasteConsumptionReport/PasteConsumptionReport.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SaleOrderCostingViewComponent } from './Costing/SaleOrderCostingView/SaleOrderCostingView.component';
import { SingleOrderCostingPrint } from './Costing/SingleOrderCostingPrint/SingleOrderCostingPrint.component';
import { SaleOrderCostingService } from './Services/SaleOrderCostingService';
import { IssueSlipPrintComponent } from './Issue/IssueSlipPrint/issueslipprint.component';
import { FormulationPrintComponent } from './Production/FormulationCode/Print/formulationprint.component';
import { PostProcessOptionsComponent } from './SalesOrder/SalesOrder/post-process-options/post-process-options.component';
import { MeasurementConversionNewComponent } from './QuickTools/measurement-conversion-new/measurement-conversion-new.component';
import { FactoryWorkersComponent } from './Master/factoryworkers/factoryworkers.component';
import { DesignationComponent } from './Master/designation/designation.component';
import { OutPassPurposeComponent } from './Master/OutPassPurpose/outpasspurpose.component';
import { DigitalWeightComponent } from './IoTDevices/DigitalWeight/digital-weight/digital-weight.component';
import { KnittingDivisionStockListComponent } from './InventoryManagement/KnittingDivisionStockList/KnittingDivisionStockList.component';
import { PoTimelineComponent } from './PoTimeLine/PoTimeline.component';
import { PoTimelineService } from './Services/PoTimeLineService';
import { CostEstimationComponent } from './Costing/Estimation/Add/costEstimationList.component';
import { PoDrawerService } from './Services/PoDrawerService';
import { PoDrawerViewComponent } from './PoDrawerView/PoDrawerView.component';
import { SoDrawerComponent } from './SoDrawer/SoDrawer.component';
import { SoDrawerService } from './Services/SoDrawerService';
import { PIDrawerViewComponent } from './PIDrawerView/PIDrawerView.component';
import { PIDrawerService } from './Services/PIDrawerService';
import { CostEstimationListComponent } from './Costing/Estimation/List/costEstimationList.component';
import { EODrawerComponent } from './EODrawer/EODrawer.component';
import { EODrawerService } from './Services/EODrawerService';
import { CostEstimationListPrintComponent } from './Costing/Estimation/PrintList/costEstimationListPrint.component';
import { CostEstimationPrintComponent } from './Costing/Estimation/Print/costEstimationPrint.component';
import { SalesOrderTrailsReportComponent } from './SalesOrder/SalesOrder/SalesOrderTrailsReport/SalesOrderTrailsReport.component';
import { OverheadCostComponent } from './Costing/Overhead/OverheadCost.component';
import { JumboPrintComponent } from './Production/WorkPlan/jumboprint/jumboprint.component';
import { ManufacturingHeartbeatReportComponent } from './Reports/ManufacturingHeartbeatReport/ManufacturingHeartbeatReport.component';
import { OutpassTimelineService } from './Services/OutpassTimelineService';
import { OutpassTimelineComponent } from './OutPass/OutpassTimeline/OutpassTimelineView.component';

registerLocaleData(en);
declare var AzureStorage: IAzureStorage;
declare var ClientID: any;
const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    var load = appConfig.loadAppConfig();
    //ClientID = appConfig.getHostname();
    return load;
  };
};
const callClientID = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.getConfig();
  };
};
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: ActiveX.clientId,
      authority: ActiveX.authority,
      redirectUri: ActiveX.redirectUri,
      postLogoutRedirectUri: ActiveX.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]); // Prod environment. Uncomment to use.
  //protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: [`${ActiveX.clientId}/.default`],
    },
  };
}

@NgModule({
  declarations: [
    HomeComponent,
    StockListComponent,
    StockInspectionComponent,
    StockProductAllocationComponent,
    AddStockComponent,
    StockDetailsComponent,
    ProductListComponent,
    AuthComponent,
    LogoutComponent,
    IndexComponent,
    StockManageRejectedItemsComponent,
    SupplierListComponent,
    GateInComponent,
    GatePassComponent,
    GateOutComponent,
    TransportManagementComponent,
    BranchComponent,
    DepartmentComponent,
    TagComponent,
    StoreComponent,
    RackComponent,
    ProductCategoryComponent,
    ProductFirstSubCategoryComponent,
    ProductSecSubCategoryComponent,
    PaymentTermComponent,
    DeliveryTermComponent,
    PurchaseorderComponent,
    POListComponent,
    MeasurementConversionComponent,
    MeasurementConversionNewComponent,
    ColorComponent,
    ElementComponent,
    GrainComponent,
    CustomerListComponent,
    IssueComponent,
    IssueListComponent,
    CostingComponent,
    CostingListComponent,
    AdminComponent,
    ProcessComponent,
    ProcessListComponent,
    MixingComponent,
    MixingListComponent,
    DatetimeConverter,
    RoundOff,
    GatepassprintComponent,
    POprintComponent,
    POEmailComponent,
    EmbossingListComponent,
    TumblingListComponent,
    VaccumListComponent,
    LacquerListComponent,
    PostprocessprintComponent,
    MixingprintComponent,
    ProcessprintComponent,
    OpeningStockComponent,
    StockComponent,
    SaleslistComponent,
    StartscreenComponent,
    PresalesorderComponent,
    CostEstimationComponent,
    CostEstimationListComponent,
    FormulationCodeListComponent,
    FormulationPrintComponent,
    FormulationcodeComponent,
    NewmixingComponent,
    ThicknessComponent,
    JumboMasterComponent,
    WorkplanListComponent,
    LoaderComponent,
    WidthComponent,
    ConsumptionComponent,
    ConsumptionListComponent,
    ProductWiseStockComponent,
    StoreWiseStockComponent,
    CallbackPipe,
    ResponsibilityMasterComponent,
    RolesMasterComponent,
    StartProductionComponent,
    PostprocessComponent,
    DispatchComponent,
    proformaAddComponent,
    proformaListComponent,
    proformaPrintComponent,
    FinalInspectionComponent,
    FinalInspectionList,
    JumboListComponent,
    InspectionPrintComponent,
    OutPassListComponent,
    OutPassAddComponent,
    OutPassPrintComponent,
    MeasurementUnitComponent,
    WithSupplierProductWiseStockReportComponent,
    PurchaseReportComponent,
    ProductStockHistoryReportComponent,
    BankDetailsComponent,
    CategoryWiseStock,
    UsersLoginHistoryComponent,
    DemandComponent,
    DemandListComponent,
    YieldReportComponent,
    WastageReportComponent,
    ProductionPlanningReportComponent,
    PostProcessReportComponent,
    ProductStockSummaryReportComponent,
    PackagingComponent,
    PackagingListComponent,
    InspectionAllPrintComponent,
    PackingListPrintComponent,
    MBFormulationComponent,
    ActivityLogComponent,
    LinkSaleOrderComponent,
    ProductionStatusReportComponent,
    UnauthorizedComponent,
    GeneralConfigurationComponent,
    TimelineComponent,
    PoTimelineComponent,
    PoDrawerViewComponent,
    SoDrawerComponent,
    EODrawerComponent,
    SalesOrderTrailsReportComponent,
    PIDrawerViewComponent,
    CostEstimationListPrintComponent,
    CostEstimationPrintComponent,
    CostingListPrintComponent,
    ConsumptionPendingListComponent,
    SalesReportComponent,
    ConsumptionReportComponent,
    PackagingListEmailComponent,
    InspectionDetailsPrintComponent,
    PasteConsumptionReportComponent,
    SaleOrderCostingViewComponent,
    SingleOrderCostingPrint,
    IssueSlipPrintComponent,
    OverheadCostComponent,
    PostProcessOptionsComponent,
    DesignationComponent,
    FactoryWorkersComponent,
    OutPassPurposeComponent,
    DigitalWeightComponent,
    KnittingDivisionStockListComponent,
    JumboPrintComponent,
    ManufacturingHeartbeatReportComponent,
    OutpassTimelineComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PmsUIAppRoutingModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    PmsUINzAntModule,
    MsalModule, NgxPrintModule,
    NzQRCodeModule,
    DragDropModule,

    // MsalModule.forRoot(
    //   new PublicClientApplication({
    //     auth: {
    //       clientId: ActiveX.clientId,
    //       authority: ActiveX.authority,
    //       redirectUri: ActiveX.redirectUri,
    //       navigateToLoginRequestUrl : true
    //     },
    //     cache: {
    //       cacheLocation: BrowserCacheLocation.LocalStorage,
    //       storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
    //     },
    //   }),
    //   {
    //     interactionType: InteractionType.Redirect,
    //     authRequest: {
    //       scopes: [`${ActiveX.clientId}/.default`]
    //     },
    //   },
    //   {
    //     interactionType: InteractionType.Redirect,
    //     protectedResourceMap: new Map([
    //       ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    //     ]),
    //   }
    // ),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
    AlertMessageService,
    LoadingService,
    DemandPopService,
    TimelineService,
    PoTimelineService,
    PoDrawerService,
    PIDrawerService,
    SoDrawerService,
    EODrawerService,
    ApiHttpService,
    BlobStorageService,
    SaleOrderCostingService,
    OutpassTimelineService,
    {
      provide: BLOB_STORAGE_TOKEN,
      useValue: AzureStorage.Blob,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    WINDOW_PROVIDERS,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    DatePipe,
    DatetimeConverter
  ],
  bootstrap: [IndexComponent, MsalRedirectComponent],
})
export class PmsUIAppModule { }
